import { Api } from './Api'
import axios from 'axios'

export class EventoService extends Api {

    constructor () {
        super();
    }

    listar(queryString) {
      queryString = super.getQueryString(queryString);
      console.log(this.getHeaders());
      return axios.get(`${this.API_URL}/api/eventos?${queryString}`,  this.getHeaders());
  }

}
