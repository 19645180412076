import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import moment from 'moment'
import VueConfirmDialog from 'vue-confirm-dialog'
import Vuelidate from "vuelidate"
import VueTheMask from 'vue-the-mask'

import ContainerComponente from './components/Container'
import InputComponente from './components/Input'
import dadosMixin from './mixins/index'
import { EventoService, PalestraService, ParticipanteService } from './services'

Vue.config.productionTip = false

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('input-componente',InputComponente)
Vue.component('container-componente',ContainerComponente)
Vue.use(Vuelidate);
Vue.use(VueTheMask)

Vue.filter("timeformat", arg => {
  return moment(arg).format("DD/MM/YYYY HH:mm:ss");
});

Vue.filter("dateformat", arg => {
  return moment(arg).format("DD/MM/YYYY");
});

new Vue({
  router,
  store,
  
  mixins: [dadosMixin],
  

  data(){
    return {
        ultima_atualizacao: null,
        interval_atualizacao: null,
        ultima_sincronizacao: null,
        total_sincronizar: 0,

        timeoutSincronizarParticipantes: null,
        timeoutSincronizarPalestraParticipantes: null,
        tempo_sincronizacao: (5* 60 * 1000), //5 minutos
        loading: false
    }
  },

  methods:{

    logar: function(alertar = false){
      if(this.estaLogado){
        this.atualizar();
        return false

      }
      
      if(!this.temCredenciais){
        alert("Informe o Token do evento e a URL do servidor")
        this.$router.push({name: 'configuracoes'})
        return
      }

      this.loading =true
      const eventoService = new EventoService();
      eventoService.listar({}).then(
        response => {
      
          let evento = response.data[0]
          this.$store.commit('EVENTO', evento);
          this.$store.commit('ESTA_LOGADO', true);
          
          
          if(alertar){
            setTimeout(() => {
              alert("Conectado com sucesso ao servidor!");
            }, 200);
          }
          this.atualizar();
        }).catch( erro => {
          console.log(erro, erro.response);
          setTimeout(() => {
            alert("Não foi possível verificar o token do evento no servidor")
          }, 200);
        }).finally( () => this.loading = false)
    },

    atualizarEvento: function(alertar = false){
  
      
      if(!this.temCredenciais){
        alert("Informe o Token do evento e a URL do servidor")
        this.$router.push({name: 'configuracoes'})
        return
      }

      this.loading =true
      const eventoService = new EventoService();
      eventoService.listar({}).then(
        response => {
      
          let evento = response.data[0]
          this.$store.commit('EVENTO', evento);
          
          
        }).catch( erro => {
          console.log(erro, erro.response);
          setTimeout(() => {
            alert("Não foi possível verificar o token do evento no servidor")
          }, 200);
        }).finally( () => this.loading = false)
    },

    sincronizarPalestraParticipantes(){
      clearTimeout(this.timeoutSincronizarPalestraParticipantes);
      this.timeoutSincronizarPalestraParticipantes = setTimeout(() => {
        this.sincronizarPalestraParticipantes();
      }, this.tempo_sincronizacao);

      let palestra = this.$store.state.palestra ;
      if(!palestra || !palestra.id)
        return false;
      
      const palestraService = new PalestraService();
      palestraService.listarParticipantes(palestra.evento_id, palestra.id, {}).then(
        response => {
          
          let par = response.data.data;
          this.tratarParticipantesPalestra(par);
        })

    },

    sincronizarParticipantes(){      
      clearTimeout(this.timeoutSincronizarParticipantes);
      this.timeoutSincronizarParticipantes = setTimeout(() => {
        this.sincronizarParticipantes();
      }, this.tempo_sincronizacao);

      let evento = this.$store.state.evento ;
      if(!evento || !evento.id)
        return false;
      const participanteService = new ParticipanteService();
      participanteService.listar(evento.id, {}).then(
        response => {
          this.tratarParticipantes(response.data.data);
        })

    },


    tratarParticipantes(participantesServidor){
      let participantesLocais = this.$store.state.participantes 
      // participantesLocais = participantesLocais.filter(participante => participante.evento_id === this.$store.state.evento.id);
      participantesServidor.forEach(participanteServidor => {
        let indice = participantesLocais.map(x => x.id).indexOf(participanteServidor.id);
        if(indice == -1){
          participantesLocais.push({
            ...participanteServidor, sincronizar: false
          })
        }else{
          Object.keys(participanteServidor).forEach((k) => {
            if(k == 'acessos'){
              let a = [...participanteServidor.acessos, ...participantesLocais[indice].acessos]
              a = [...new Set(a)];
              a = a.map(x => {
                x = x.split(' ')
                return {
                  tipo: x[0],
                  data: moment(`${x[1]} ${x[2]}`, 'DD/MM/YYYY HH:mm:ss')
                }
              })
              a.sort( (x,y) => {
                return Number(x.data.format('YYYYMMDDHHmmss')) - Number (y.data.format('YYYYMMDDHHmmss'))
              })

              a = a.map(x => `${x.tipo} ${x.data.format('DD/MM/YYYY HH:mm:ss')}`)
              participantesLocais[indice].acessos = a;
              if(participantesLocais[indice].acessos.length > participanteServidor.acessos.length)
                participantesLocais[indice].sincronizar = true;

            }
            else if(k == 'credenciado_em' || k == 'kit_retirado_em'){
              participantesLocais[indice].credenciado_em = participantesLocais[indice].credenciado_em != null ? participantesLocais[indice].credenciado_em : participanteServidor.credenciado_em;
              participantesLocais[indice].kit_retirado_em = participantesLocais[indice].kit_retirado_em != null ? participantesLocais[indice].kit_retirado_em : participanteServidor.kit_retirado_em;

              participantesLocais[indice].sincronizar = participanteServidor.credenciado_em != participantesLocais[indice].credenciado_em || participanteServidor.kit_retirado_em != participantesLocais[indice].kit_retirado_em;
            }else{
              participantesLocais[indice][k] = participanteServidor[k]
            }

            
          });
        }
      })

      this.$store.commit('ULTIMA_SINCRONIZACAO', new Date());
      this.$store.commit('PARTICIPANTES', participantesLocais);

      let participantesAtualizar = participantesLocais.filter(x => x.sincronizar);

      if(participantesAtualizar.length > 0){
        
        this.total_sincronizar = participantesAtualizar.length;
        console.log(`${this.total_sincronizar} participantes para sincronizar!`)

        let evento = this.$store.state.evento ;

        for(let i = 0; i < participantesLocais.length; i++){
          
          if(participantesLocais[i].sincronizar){

            let obj = {}
            if(participantesLocais[i].credenciado_em != null){
              obj.credenciado_em = moment(participantesLocais[i].credenciado_em).format('YYYYMMDDHHmmss');
            }
            if(participantesLocais[i].kit_retirado_em != null){
              obj.kit_retirado_em = moment(participantesLocais[i].kit_retirado_em).format('YYYYMMDDHHmmss');
            }

            if(participantesLocais[i].acessos.length > 0){
              obj.acessos = participantesLocais[i].acessos
            }
                      
            const participanteService = new ParticipanteService();
            participanteService.atualizar(evento.id,participantesLocais[i].id, obj ).then(
              response => {
                participantesLocais[i].sincronizar = false;
                participantesLocais[i].credenciado_em = response.data.credenciado_em;
                participantesLocais[i].kit_retirado_em = response.data.kit_retirado_em;
                this.$store.commit('ULTIMA_SINCRONIZACAO', new Date());
                this.$store.commit('PARTICIPANTES', participantesLocais);
              }).catch(erro =>{
                console.log("Erro ao tentar sincronizar participante: ", participantesLocais[i], erro)
              }).finally( () => {
                this.total_sincronizar--;
                console.log(`${this.total_sincronizar} participantes pendentes de atualização!`)
              })
          }
        }      
      }      
    },

    tratarParticipantesPalestra(participantesServidor){
      let participantesLocais = this.$store.state.participantes_palestra
         
      participantesServidor.forEach(p => {
        let i = participantesLocais.map(x => x.id).indexOf(p.id);
        if(i == -1){
          participantesLocais.push({
            ...p, sincronizado: p.hora_entrada != null
          })
        }else{          
          Object.keys(p).forEach((k) => {
            if(!(k == 'hora_entrada') || participantesLocais[i]['hora_entrada'] == null)
              participantesLocais[i][k] = p[k];
          });
        }
      })
      this.$store.commit('ULTIMA_SINCRONIZACAO', new Date());
      this.$store.commit('PARTICIPANTES_PALESTRA', participantesLocais);

      let participantesAtualizar = participantesLocais.filter(x => x.hora_entrada != null && !x.sincronizado);

      if(participantesAtualizar.length > 0){
        
        this.total_sincronizar = participantesAtualizar.length;

        let palestra = this.$store.state.palestra ;

        for(let i = 0; i < participantesLocais.length; i++){
          
          if(participantesLocais[i].hora_entrada != null && !participantesLocais[i].sincronizado){
                    
            const palestraService = new PalestraService();
            palestraService.atualizarParticipante(palestra.id,participantesLocais[i].id, {hora_entrada: moment(participantesLocais[i].hora_entrada).format('YYYYMMDDHHmmss')} ).then(
              response => {
                participantesLocais[i].sincronizado = true;
                participantesLocais[i].hora_entrada = response.data.hora_entrada;
                this.$store.commit('ULTIMA_SINCRONIZACAO', new Date());
                this.$store.commit('PARTICIPANTES_PALESTRA', participantesLocais);
              }).catch(erro =>{
                console.log("Erro ao tentar soncronizar participantes da palestra!", erro, erro.response);
              }).finally( () => {
                this.total_sincronizar--;
              })
          }
        }
      
      }        
      
    },

    iniciar_atualizacao(){
      this.cancelar_atualizacao();
      if(!this.conectado){
        alert("Não conectado ao servidor para sincronização!");
        return 0;
      }

      this.interval_atualizacao = setInterval(() => {
          this.atualizar();
      }, (5 * 60 * 1000)); // 5 minutos
    },

    cancelar_atualizacao(){
      clearInterval(this.interval_atualizacao);
    },

    atualizar(tentativa = 0){
      if(!this.conectado){
        this.logar();
        if(tentativa < 10){
          setTimeout(() => {
            this.atualizar(tentativa++);
          }, 5000);
          return 0
        }else{
          alert("Verifique a conexão");
          return 0;
        }
      }
      console.log("Sincronizando participantes");
      this.sincronizarParticipantes();
      this.sincronizarPalestraParticipantes();
      this.ultima_atualizacao = new Date();
    },
  },
  
  mounted(){
    this.logar()

    setInterval(() => {
      this.logar()
    }, (30 * 60 * 1000)); // 30 minutos

    //   this.sincronizarParticipantes()

    // setTimeout(() => {
    //   this.sincronizarPalestraParticipantes()
    // }, 10000);
  },

  render: h => h(App)
}).$mount('#app')
