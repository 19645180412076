<template>
  <div>
    <div class="card card-default">
			<div class="card-header card-header-border-bottom">
				<h2>{{label}}</h2>
			</div>

			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item">
						<a class="nav-link" :class="{'active': aba == 'dashboard'}" @click.prevent="aba = 'dashboard'" id="icon-home-tab" href="#" >
              Dados
            </a>
					</li>

					<li class="nav-item">
						<a class="nav-link" :class="{'active': aba == 'cadastro'}" @click.prevent="aba = 'cadastro'"  id="icon-profile-tab" href="#" >
              Cadastro
            </a>
					</li>

					<li class="nav-item">
						<a class="nav-link" :class="{'active': aba == 'entrega'}" @click.prevent="aba = 'entrega'"  id="icon-contact-tab" href="#" >
							Entrega de Kits
            </a>
					</li>

          <li class="nav-item">
						<a class="nav-link" :class="{'active': aba == 'teste'}" @click.prevent="aba = 'teste'"  id="icon-contact-tab" href="#" >
							Teste de Impressão
            </a>
					</li>
				</ul>

				<div class="tab-content" id="myTabContent2">
					<div class="tab-pane pt-3 fade active show" id="icon-home" role="tabpanel" aria-labelledby="icon-home-tab" v-if="aba == 'cadastro'">
              <Participantes />
					</div>
          <div class="tab-pane pt-3 fade active show" id="icon-home" role="tabpanel" aria-labelledby="icon-home-tab" v-if="aba == 'dashboard'">
              <Dashboard />	
					</div>

          <div class="tab-pane pt-3 fade active show" id="icon-home" role="tabpanel" aria-labelledby="icon-home-tab" v-if="aba == 'entrega'">
              <EntregaKits />	
					</div>

          <div class="tab-pane pt-3 fade active show" id="icon-home" role="tabpanel" aria-labelledby="icon-home-tab" v-if="aba == 'teste'">
              <container-componente label="Teste de Impressão de Etiqueta">
                <button @click="impressaoTeste()" type="button" title="Imprimir etiqueta de teste" class="btn btn-outline-primary"><i class="mdi mdi-printer mr-1"></i> Imprimir Etiqueta de Teste </button>
              </container-componente>
					</div>

				
				</div>
			</div>
		</div>

  </div>
</template>

<script>
import Participantes from '../components/Participantes'
import Dashboard from '../components/Dashboard'
import EntregaKits from './EntregaView'
import {ImpressaoService} from '../services'

const impressaoService = new ImpressaoService();

export default {
  components: {
    Participantes,
    EntregaKits,
    Dashboard

  },
  name: 'CadastroView',
  data(){
    return {
      aba: 'dashboard',
    }
  },
  methods:{
    impressaoTeste(){
      impressaoService.imprimirTeste().then( response => console.log(response)).catch((error) => console.error(error))
        .finally(() => {
          this.limparDados()
          // setTimeout(() => {
          //   this.loader = false;
          //   this.$confirm({
          //     title: 'Confirmação de Impressão!',
          //     message: 'Sua etiqueta foi impressa corretamente?',
          //     button: {
          //       yes: 'Sim',
          //       no: 'Não'
          //     },
          //     callback: confirm => {
          //       if(confirm){
          //         setTimeout(() => {
          //           this.limparDados()
          //         }, 500);
          //       }
          //     }
          //   })
          // }, 1500);
        })
    }
  },
  computed:{
    label(){
      switch(this.aba){
        case 'cadastro' : return 'Cadastro de Participantes'
        case 'dashboard' : return 'Dados do Evento'
        case 'entrega' : return 'Entrega de Kits'
      }
    }
  }
}
</script>
